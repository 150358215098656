import React, { useRef, useState } from "react";
import notify from "../utils/notify";
import PageWrapper from "../components/PageWrapper";
import { storeContactUsDetails } from "../api";
import { useForm } from "react-hook-form";
import { validation } from "../utils/validation";
import emailjs from "emailjs-com";

const Contact = () => {
  const [submitting, setSubmitting] = useState(false);

  const formRef = useRef();

  const { register, errors, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    setSubmitting(true);
    const formData = data;

    try {
      const payload = {
        ...formData,
      };

      //send email here
      await emailjs.sendForm(
        process.env.GATSBY_EMAIL_SERVICE_ID,
        process.env.GATSBY_EMAIL_TEMPLATE_ID,
        formRef.current,
        process.env.GATSBY_EMAIL_SERVICE_PUBLICKEY
      );

      //store in db
      const response = await storeContactUsDetails(payload);
      if (response) {
        reset();
        notify("Message Sent!", "success");
        notify("Thank you for contacting us!", "success");
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="bg-default-2 pt-16 pb-12 pt-lg-22 pb-lg-27">
          <div className="container">
            <div className="row justify-content-center mt-14">
              <div className="col-xxl-6 col-xl-7 col-lg-8">
                <h3 className="font-size-8 text-center mb-11">Contact Us</h3>
                <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    ref={formRef}
                  >
                    <div className="row">
                      <div className="col-12 mb-7">
                        <label
                          htmlFor="contactName"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Names"
                          id="contactName"
                          name="contactName"
                          ref={register(validation.contactName)}
                        />
                        <span className="text-danger small">
                          {errors?.contactName?.message || ""}
                        </span>
                      </div>
                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="email"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          E-mail
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="example@gmail.com"
                          id="email"
                          name="email"
                          ref={register(validation.email)}
                        />
                        <span className="text-danger small">
                          {errors?.email?.message || ""}
                        </span>
                      </div>

                      <div className="col-lg-6 mb-7">
                        <label
                          htmlFor="contactSubject"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Message Subject"
                          id="contactSubject"
                          name="contactSubject"
                          ref={register(validation.contactSubject)}
                        />
                        <span className="text-danger small">
                          {errors?.contactSubject?.message || ""}
                        </span>
                      </div>
                      <div className="col-lg-12 mb-7">
                        <label
                          htmlFor="contactMessage"
                          className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
                        >
                          Message
                        </label>
                        <textarea
                          id="contactMessage"
                          placeholder="Type your message"
                          type="text"
                          className="form-control h-px-144"
                          name="contactMessage"
                          ref={register(validation.contactMessage)}
                        ></textarea>
                        <span className="text-danger small">
                          {errors?.contactMessage?.message || ""}
                        </span>
                      </div>
                      <div className="col-lg-12 pt-4">
                        <button
                          type="submit"
                          className="btn btn-primary text-uppercase w-100 h-px-48 focus-reset"
                          disabled={submitting ? true : false}
                        >
                          Send Now
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="mt-8">
                    <h3 className="font-size-4">Contact Information</h3>
                    <div className="media mb-2">
                      <div className="mr-6">
                        <i className="fas fa-map-marker-alt mt-2"></i>
                      </div>
                      <p className="font-size-4 mb-0">
                        Africa <br />
                        Kigali/ Nairobi
                      </p>
                    </div>
                    <div className="media mb-2">
                      <div className="mr-6">
                        <i className="fas fa-phone-alt mt-2"> </i>
                      </div>
                      <div> 
                      <span className="font-weight-semibold mr-4">RW</span>
                      <a
                        href="tel: (+250) 788 384 146"
                        className="font-size-4 mb-0 text-gray"
                      >
                        (+250) 788 384 146
                      </a>
                    </div>
                    </div>
                    <div className="media mb-2">
                      <div className="mr-6">
                        <i className="fas fa-phone-alt mt-2"> </i>
                      </div>
                      <div> 
                      <span className="font-weight-semibold mr-4">KE</span>
                      <a
                        href="tel: (+254) 115 602 345"
                        className="font-size-4 mb-0 text-gray"
                      >
                        (+254) 115 602 345
                      </a>
                    </div>
                    </div>
                    <div className="media mb-2">
                      <div className="mr-6">
                        <i className="fas fa-envelope mt-2"></i>
                      </div>
                      <a
                        href="mailto:info@devshopafrica.com"
                        className="font-size-4 mb-0 text-gray"
                      >
                        info@devshopafrica.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
